// packages
import React from "react";
import styled from "styled-components";
// components
import { Link } from "gatsby";
import NewsletterSignup from "./NewsletterSignup";
// functions
import { mediaQuery } from "../components/Theme";
// media
import BaseBlackYetiLogo from "../images/svgs/black-yeti-logo.svg";
import BasePolitikLogo from "../images/svgs/politik-logo.svg";

const MobileNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mediaQuery("display", "", [{ 1200: "none" }])};
`;

const BlackYetiLogo = styled(BaseBlackYetiLogo)`
  width: 66.66vw;
`;

const MobileLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 20rem;
  margin-top: 2rem;
`;

const MobileBlackYetiLink = styled(Link)`
  width: fit-content;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 1.5rem;
  font-weight: normal;
  text-decoration: none;
  color: #000000;
  padding: 0 0.5rem;
  box-shadow: 0px 2px ${props => props.theme.colors.iceBlue};
`;

// const MobileDesignerInsightsLink = styled(Link)`
//   width: fit-content;
//   font-family: ${props => props.theme.fonts.primary};
//   font-size: 1.5rem;
//   font-weight: normal;
//   text-decoration: none;
//   color: #000000;
//   padding: 0 0.5rem;
//   box-shadow: 0px 2px ${props => props.theme.colors.iceBlue};
// `;

const MobilePolitikLink = styled(Link)`
  width: fit-content;
`;

const MobilePolitikLogo = styled(BasePolitikLogo)`
  height: 2.5rem;
`;

const MobileIndexNav = () => {
  return (
    <MobileNavContainer>
      <BlackYetiLogo />
      <MobileLinkContainer>
        <MobileBlackYetiLink to="/about-black-yeti">about black yeti</MobileBlackYetiLink>
        {/* <MobileDesignerInsightsLink to="/designer-insights">
          designer insights
        </MobileDesignerInsightsLink> */}
        <MobilePolitikLink to="/politik">
          <MobilePolitikLogo />
        </MobilePolitikLink>
        <NewsletterSignup />
      </MobileLinkContainer>
    </MobileNavContainer>
  );
};

export default MobileIndexNav;
