// packages
import React, { useState } from "react";
import styled, { css } from "styled-components";
// components
import Seo from "../components/Seo";
import { Link } from "gatsby";
import BlankLayout from "../components/BlankLayout";
import MobileIndexNav from "../components/MobileIndexNav";
import NewsletterSignup from "../components/NewsletterSignup";
// functions
import { mediaQuery } from "../components/Theme";
// media
import BaseBlackYetiHead from "../images/svgs/black-yeti-head.svg";
import BaseBlackYetiText from "../images/svgs/black-yeti-text.svg";
import BasePolitikLogo from "../images/svgs/politik-logo.svg";

const HOVER_STATES = {
  BLACK_YETI: "BLACK_YETI",
  DESIGNER_INSIGHTS: "DESIGNER_INSIGHTS",
  POLITIK: "POLITIK",
  NEWSLETTER: "NEWSLETTER"
};

const ContentContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DesktopNavContainer = styled.div`
  display: none;
  ${mediaQuery("display", "", [{ 1200: "flex" }])};
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 2rem;
`;

const BlackYetiLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: rotate(-10deg);
`;

const StyledBlackYetiText = styled(BaseBlackYetiText)`
  width: 35rem;
  color: ${props => props.theme.colors.byBlack};
  transition: ${props => props.theme.transitions.standard};
  ${BlackYetiLink}:hover & {
    transform: scale(1.1);
  }
`;

const BlackYetiHoverBar = styled.div`
  position: absolute;
  z-index: -1;
  height: 2rem;
  width: 40rem;
  background-color: ${props => props.theme.colors.iceBlue};
  opacity: 0;
  transition: ${props => props.theme.transitions.standard};
  ${BlackYetiLink}:hover & {
    opacity: 1;
  }
`;

// const DesignerInsightsLink = styled(Link)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: relative;
//   text-decoration: none;
//   margin-top: -2rem;
// `;

// const DesignerInsightsText = styled.span`
//   font-family: ${props => props.theme.fonts.primary};
//   font-size: 4.625rem;
//   font-weight: ${props => props.theme.fontWeights.thin};
//   line-height: 1;
//   color: ${props => props.theme.colors.byBlack};
//   transition: ${props => props.theme.transitions.standard};
//   ${DesignerInsightsLink}:hover & {
//     transform: scale(1.1);
//   }
// `;

// const DesignerInsightsHoverBar = styled.div`
//   position: absolute;
//   z-index: -1;
//   height: 1.5rem;
//   width: 40rem;
//   margin-top: 1rem;
//   background-color: #a2d2df;
//   opacity: 0;
//   transition: ${props => props.theme.transitions.standard};
//   ${DesignerInsightsLink}:hover & {
//     opacity: 1;
//   }
// `;

const PolitikLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* transform: rotate(10deg); */
  text-decoration: none;
  margin: 4rem 0 6rem 0;
`;

const StyledPolitikLogo = styled(BasePolitikLogo)`
  width: 35rem;
  transition: ${props => props.theme.transitions.standard};
  ${PolitikLink}:hover & {
    transform: scale(1.1);
  }
`;

const PolitikHoverBar = styled.div`
  position: absolute;
  z-index: -1;
  height: 2rem;
  width: 40rem;
  background-color: ${props => props.theme.colors.iceBlue};
  opacity: 0;
  transition: ${props => props.theme.transitions.standard};
  ${PolitikLink}:hover & {
    opacity: 1;
  }
`;

const NewsletterContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  transform: rotate(10deg);
  text-decoration: none;
`;

const StyledBlackYetiHead = styled(BaseBlackYetiHead)`
  height: 30rem;
  fill: ${props => props.theme.colors.byBlack};
  transition: ${props => props.theme.transitions.standard};
  ${props =>
    props.hovered === HOVER_STATES.BLACK_YETI &&
    css`
      transform: rotate(-10deg);
    `};
  /* ${props =>
    props.hovered === HOVER_STATES.DESIGNER_INSIGHTS &&
    css`
      transform: rotate(0deg);
    `}; */
  ${props =>
    props.hovered === HOVER_STATES.POLITIK &&
    css`
      /* transform: rotate(10deg); */
      transform: rotate(0deg);
    `};
  ${props =>
    props.hovered === HOVER_STATES.NEWSLETTER &&
    css`
      transform: rotate(10deg);
    `};
`;

const CopyrightText = styled.span`
  position: fixed;
  right: 0;
  bottom: 0;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 0.5rem;
  line-height: 0.5rem;
  padding: 0.25rem;
`;

const IndexPage = () => {
  const [hovered, setHovered] = useState(null);

  return (
    <BlankLayout>
      <Seo title="Home" />
      <ContentContainer>
        <MobileIndexNav />
        <DesktopNavContainer>
          <StyledBlackYetiHead hovered={hovered} />
          <LinkContainer>
            <BlackYetiLink
              to="/about-black-yeti"
              onMouseEnter={() => setHovered(HOVER_STATES.BLACK_YETI)}
              onMouseLeave={() => setHovered(null)}
            >
              <StyledBlackYetiText />
              <BlackYetiHoverBar />
            </BlackYetiLink>
            {/* <DesignerInsightsLink
              to="/designer-insights"
              onMouseEnter={() => setHovered(HOVER_STATES.DESIGNER_INSIGHTS)}
              onMouseLeave={() => setHovered(null)}
            >
              <DesignerInsightsText>designer insights</DesignerInsightsText>
              <DesignerInsightsHoverBar />
            </DesignerInsightsLink> */}
            <PolitikLink
              to="/politik"
              onMouseEnter={() => setHovered(HOVER_STATES.POLITIK)}
              onMouseLeave={() => setHovered(null)}
            >
              <StyledPolitikLogo />
              <PolitikHoverBar />
            </PolitikLink>
            <NewsletterContainer
              onMouseEnter={() => setHovered(HOVER_STATES.NEWSLETTER)}
              onMouseLeave={() => setHovered(null)}
            >
              <NewsletterSignup />
            </NewsletterContainer>
          </LinkContainer>
        </DesktopNavContainer>
        <CopyrightText>© Black Yeti Games 2022</CopyrightText>
      </ContentContainer>
    </BlankLayout>
  );
};

export default IndexPage;
