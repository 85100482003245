// packages
import React, { useState, useRef } from "react";
import styled, { keyframes, useTheme } from "styled-components";
import addToMailchimp from "gatsby-plugin-mailchimp";
// functions
import { mediaQuery } from "./Theme";

const FormContainer = styled.div`
  position: relative;
  width: 100%;
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${mediaQuery("align-items", "", [{ 1200: "flex-start" }])};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Header = styled.h3`
  font-family: ${props => props.theme.fonts.header};
  font-size: 2rem;
  margin: 0 0 0.5rem 0;
`;

const FocusBar = styled.div`
  display: none;
  position: absolute;
  z-index: -1;
  height: 0.5rem;
  width: 15rem;
  margin-top: -0.5rem;
  background-color: ${props => props.theme.colors.iceBlue};
  opacity: 0;
  transition: ${props => props.theme.transitions.standard};
  ${FormContainer}:hover & {
    opacity: 1;
  }
  ${mediaQuery("display", "", [{ 1200: "block" }])};
`;

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  ${mediaQuery("flex-direction", "", [{ 1200: "row" }])};
`;

const Label = styled.label`
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
`;

const Input = styled.input`
  width: 100%;
  margin: 0 0 0 0;
  padding: 0.5rem 0.75rem;
  border: 2px solid black;
  border-radius: 4px;
  &:focus-visible {
    outline: 2px solid ${props => props.theme.colors.iceBlue};
    border: 2px solid ${props => props.theme.colors.iceBlue};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    font-family: ${props => props.theme.fonts.primary} !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  ${mediaQuery("margin", "", [{ 1200: "0 1rem 0 0" }])};
  ${mediaQuery("width", "%", [{ 1200: 66.66 }])};
`;

const Button = styled.button`
  height: fit-content;
  border: none;
  background: transparent;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 1rem;
  font-weight: normal;
  text-decoration: none;
  color: #000000;
  margin-top: 1rem;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 2px ${props => props.theme.colors.iceBlue};
  }
  ${mediaQuery("margin-top", "rem", [{ 1200: 0 }])};
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const Message = styled.p`
  position: absolute;
  font-weight: normal;
  text-align: center;
  animation: ${fadeOut} 1s linear 3s normal both;
  ${mediaQuery("text-align", "", [{ 1200: "left" }])};
`;

const NewsletterSignup = () => {
  const [status, setStatus] = useState("Subscribe");
  const [result, setResult] = useState(null);

  const emailInput = useRef();

  const theme = useTheme();

  const handleSubmit = async e => {
    e.preventDefault();
    setStatus("Subscribing...");
    const { email } = e.target.elements;
    let submittedEmail = email.value;
    let response = await addToMailchimp(submittedEmail);
    setResult(response);
    emailInput.current.value = "";
    setStatus("Subscribe");
    setTimeout(() => {
      setResult(null);
    }, 4000);
  };

  return (
    <FormContainer>
      <form id="black-yeti-signup-form" onSubmit={handleSubmit}>
        <FormContent>
          <HeaderContainer>
            <Header>Newsletter Signup</Header>
            <FocusBar />
          </HeaderContainer>
          <Label htmlFor="email">Email</Label>
          <FieldContainer>
            <Input id="email" type="email" ref={emailInput} />
            <Button type="submit">{status}</Button>
          </FieldContainer>
        </FormContent>
      </form>
      <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
        <input
          type="text"
          name="b_4d1024ffc34af9e374ffe83b0_e53d63528d"
          tabIndex="-1"
          defaultValue=""
        />
      </div>
      {result && result.result === "error" && (
        <Message style={{ color: theme.colors.red }}>
          <span style={{ textTransform: "capitalize" }}>{result.result}</span> - {result.msg}
        </Message>
      )}
      {result && result.result === "success" && (
        <Message style={{ color: theme.colors.green }}>
          <span style={{ textTransform: "capitalize" }}>{result.result}</span> - {result.msg}
        </Message>
      )}
    </FormContainer>
  );
};

export default NewsletterSignup;
